import { graphql, PageProps, Link } from "gatsby";
import React from "react";
import Box from "../components/Box/Box";
import RingPicker from "../components/Collection/RingPicker";
import Specification from "../components/Collection/Specification";
import ImageSwitch, { ImageSwitchButtonAlign } from "../components/ImageSwitch";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo";
import LinkArrowBtn from "../components/LinkArrowBtn";
import { Routes } from "../routes";
import { SectionWrapper2 } from "../components/Styled/Containerts";
import {
  Paragraph,
  Paragraph2,
  Subtitle,
  Title,
} from "../components/Styled/Text";
import { get } from "../constants/theme";
import { clampBuilder } from "../helpers/sizeCalc";
import useWindowSize from "../hooks/useWindowSize";
import { CollectionQuery } from "../types/pages/collection";
import GridSection from "../components/GridSection";
import { GatsbyImage } from "gatsby-plugin-image";
import Wrapper from "../components/Wrapper";
import SlidingImages from "../components/SlidingImages";
import GatsbyImageWithArtDirection from "../components/GatsbyImageWithArtDirection";

interface Props extends PageProps {
  data: CollectionQuery;
}

const Collection = ({ data, location }: Props) => {
  const { height } = useWindowSize();
  return (
    <>
      <Seo
        title={data.translationsJson.pages.collection.seo.title}
        description={data.translationsJson.pages.collection.seo.description}
        keywords={data.translationsJson.pages.collection.seo.keywords}
        pathname={location.pathname}
        metaImage={
          data.translationsJson.pages.collection.seo.image.childImageSharp
            .gatsbyImageData
        }
      />
      <Layout pathname={location.pathname}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: ["auto", "auto", "auto", height],
            marginBottom: "135px",
          }}
        >
          <GatsbyImageWithArtDirection
            sx={{
              width: "100%",
              height: height,
              maxHeight: height,
            }}
            imgStyle={{ objectFit: "cover" }}
            desktopImage={data.headerDesktop.childImageSharp.gatsbyImageData}
            mobileImage={data.headerMobile.childImageSharp.gatsbyImageData}
            alt=""
          />
          <Box
            sx={{
              width: ["90%", "90%", "unset", "unset"],
              maxWidth: ["90%", "90%", "50%", "50%"],
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              textAlign: "center",
              top: ["50%", "50%", "10%", "20%"],
              left: ["50%", "50%", "7.5%", "7.5%"],
              transform: [
                "translateX(-50%) translateY(-50%)",
                "translateX(-50%) translateY(-50%)",
                "unset",
                "unset",
              ],
            }}
          >
            <Subtitle sx={{ color: "#fff", opacity: 0.85, display:"none", }}>
              {data.translationsJson.pages.collection.header.subtitle}
            </Subtitle>
            {data.translationsJson.pages.collection.header.title.map((t, i) => (
              <Title
                variant="bigHero"
                key={t}
                sx={{
                  color: "#fff",
                }}
              >
                {t}
              </Title>
            ))}

            <Paragraph2
              sx={{
                color: "#fff",
                opacity: 0.85,
                width: [
                  "90%",
                  clampBuilder(599, 1680, 19.125, 43.625),
                  clampBuilder(599, 1680, 19.125, 43.625),
                  clampBuilder(599, 1680, 19.125, 43.625),
                ],
              }}
              variant="hero"
            >
              {data.translationsJson.pages.collection.header.description}
            </Paragraph2>
          </Box>
        </Box>

        <Box
          sx={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 400,
            fontFamily: "custom_42264",
            color: get("palette.text.brown"),
            textAlign: "center",
            marginBottom: "clamp(3.875rem, -0.0677rem + 19.7133vw, 7.3125rem)",
          }}
        >
          {data.translationsJson.pages.collection.items[0].title.map((t, i) => (
            <Title
              variant="section"
              key={i}
              sx={
                i ===
                data.translationsJson.pages.collection.items[0].title.length - 1
                  ? { marginBottom: "35px" }
                  : {}
              }
            >
              {t}
            </Title>
          ))}
          <Paragraph
            sx={{
              maxWidth: [
                "100%",
                clampBuilder(599, 1680, 18.75, 40.75),
                clampBuilder(599, 1680, 18.75, 40.75),
                clampBuilder(599, 1680, 18.75, 40.75),
              ],
            }}
          >
            {data.translationsJson.pages.collection.items[0].description}
          </Paragraph>
        </Box>

        <SlidingImages
          rightSide={{
            topImage: {
              image:
                data.translationsJson.pages.collection.woman.items[0].image
                  .childImageSharp.gatsbyImageData,
              imagemobile:
                data.translationsJson.pages.collection.woman.items[0]
                  .imagemobile.childImageSharp.gatsbyImageData,
              alt: data.translationsJson.pages.collection.woman.items[0]
                .imageAlt,
              btn: {
                title:
                  data.translationsJson.pages.collection.woman.items[0].text,
              },
            },
            bottomImage: {
              image:
                data.translationsJson.pages.collection.woman.items[0].bg
                  .childImageSharp.gatsbyImageData,
              imagemobile:
                data.translationsJson.pages.collection.woman.items[0].bgmobile
                  .childImageSharp.gatsbyImageData,
              alt: data.translationsJson.pages.collection.woman.items[0]
                .imageAlt,
            },
          }}
          leftSide={{
            topImage: {
              image:
                data.translationsJson.pages.collection.woman.items[1].image
                  .childImageSharp.gatsbyImageData,
              imagemobile:
                data.translationsJson.pages.collection.woman.items[1]
                  .imagemobile.childImageSharp.gatsbyImageData,
              alt: data.translationsJson.pages.collection.woman.items[1]
                .imageAlt,
              btn: {
                title:
                  data.translationsJson.pages.collection.woman.items[1].text,
              },
            },
            bottomImage: {
              image:
                data.translationsJson.pages.collection.woman.items[1].bg
                  .childImageSharp.gatsbyImageData,
              imagemobile:
                data.translationsJson.pages.collection.woman.items[1].bgmobile
                  .childImageSharp.gatsbyImageData,
              alt: data.translationsJson.pages.collection.woman.items[1]
                .imageAlt,
            },
          }}
        />

        <Box
        sx={{
          marginBottom:"80px",
        }}
        >
          <Box>
            {/* <ImageSwitch
              btnAlign={ImageSwitchButtonAlign.CENTER}
              imageContainerSx={{
                margin: "0 auto",
                width: ["297px", "297px", "297px", "681px"],
                height: ["421px", "421px", "421px", "972px"],
              }}
              images={data.translationsJson.pages.collection.items[0].variants}
              btnContainerSx={{ width: "100%", textAlign: "center" }}
            /> */}
            <Wrapper>
              <GridSection
                firstChildrenType="textBox"
                secondChildrenType="imageBox"
                mainContainerSx={{ marginTop: "120px" }}
              >
                <>
                  <Title
                    sx={{
                      alignSelf: "center",
                      width: "100%",
                    }}
                    variant="paragraph"
                  >
                    {data.translationsJson.pages.collection.items[1].title}
                  </Title>
                  <Paragraph
                    sx={{
                      alignSelf: "center",
                      width: "100%",
                    }}
                  >
                    {
                      data.translationsJson.pages.collection.items[1]
                        .description
                    }
                  </Paragraph>
                </>
                <>
                  <ImageSwitch
                    sx={{
                      marginBottom: ["40px", "40px", "20px", "20px"],
                    }}
                    btnAlign={ImageSwitchButtonAlign.RIGHT}
                    direction="right"
                    images={
                      data.translationsJson.pages.collection.items[1].variants
                    }
                  />
                </>
              </GridSection>
            </Wrapper>
            <Specification
              listspec={data.translationsJson.pages.collection.case}
            />
            <Wrapper>
              <GridSection
                firstChildrenType="textBox"
                secondChildrenType="imageBox"
              >
                <>
                  <Title
                    sx={{
                      alignSelf: "left",
                      width: "100%",
                      
                    }}
                    variant="paragraph"
                  >
                    {data.translationsJson.pages.collection.items[5].title}
                  </Title>
                  <Paragraph
                    sx={{
                      alignSelf: "center",
                      maxWidth: "100%",
                    }}
                  >
                    {
                      data.translationsJson.pages.collection.items[5]
                        .description
                    }
                  </Paragraph>
                </>
                <>
                  <ImageSwitch
                    btnAlign={ImageSwitchButtonAlign.RIGHT}
                    images={
                      data.translationsJson.pages.collection.items[5].variants
                    }
                  />
                </>
              </GridSection>
              <GridSection
                firstChildrenType="imageBox"
                secondChildrenType="textBox"
              >
                <>
                  <ImageSwitch
                    btnAlign={ImageSwitchButtonAlign.LEFT}
                    images={
                      data.translationsJson.pages.collection.items[6].variants
                    }
                  />
                </>
                <>
                  <Title
                    sx={{
                      alignSelf: "left",
                    }}
                    variant="paragraph"
                  >
                    {data.translationsJson.pages.collection.items[6].title}
                  </Title>
                  <Paragraph
                    sx={{
                      alignSelf: "left",
                      maxWidth: "100%",
                    }}
                  >
                    {
                      data.translationsJson.pages.collection.items[6]
                        .description
                    }
                  </Paragraph>
                </>
              </GridSection>
              <GridSection
                secondChildrenType="textBox"
                firstChildrenType="imageBox"

              >

                <>
                  <Title
                    sx={{ width: "100%",}}
                    variant="paragraph"
                  >
                    {data.translationsJson.pages.collection.items[7].title}
                  </Title>
                  <Paragraph
                    sx={{
                      width: "100%",
                    }}
                  >
                    {
                      data.translationsJson.pages.collection.items[7]
                        .description
                    }
                  </Paragraph>
                </>
                <>
                  <ImageSwitch
                    btnAlign={ImageSwitchButtonAlign.RIGHT}
                    images={
                      data.translationsJson.pages.collection.items[7].variants
                    }
                  />
                </>
              </GridSection>
            </Wrapper>
            <Specification
            listspec={data.translationsJson.pages.collection.movement}
          />
            <Box
              sx={{
                display: "flex",
                flexDirection: [
                  "column",
                  "column-reverse",
                  "column-reverse",
                  "column-reverse",
                ],
                justifyContent: "space-between",
                width: "100%",
                gap: [clampBuilder(599, 1680, 2.5, 7.25)],
                padding: "0 5%",
                "& > :nth-child(1n)": {
                  flex: 1,
                },
              }}
            ></Box>
            <SectionWrapper2
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: ["center"],
                justifyContent: "center",
                textAlign: "center",
                marginBottom: clampBuilder(599, 1680, 1.8125, 4.0625),
                paddingBottom: 0, //clampBuilder(300, 840, 3, 6.8125),
                "& > h1,p": {
                  marginLeft: 0,
                },
              }}
            >
              {data.translationsJson.pages.collection.ringsCollection.title.map(
                (t, i) => (
                  <Title sx={{}} key={i} variant="section">
                    {t}
                  </Title>
                )
              )}

              <Paragraph
                sx={{
                  color: get("palette.text.brown"),
                  width: [
                    "100%",
                    clampBuilder(599, 1680, 19.5, 43.25),
                    clampBuilder(599, 1680, 19.5, 43.25),
                    clampBuilder(599, 1680, 19.5, 43.25),
                  ],
                  marginBottom: [
                    clampBuilder(320, 599, 3.375, 6.6875),
                    clampBuilder(320, 599, 3.375, 6.6875),
                    clampBuilder(599, 1680, 1.75, 4.875),
                    clampBuilder(599, 1680, 1.75, 4.875),
                  ],
                }}
              >
                {
                  data.translationsJson.pages.collection.ringsCollection
                    .description
                }
              </Paragraph>

              <RingPicker />

              <Paragraph
                sx={{
                  width: [
                    "100%",
                    clampBuilder(599, 1680, 19.5, 41.25),
                    clampBuilder(599, 1680, 19.5, 41.25),
                    clampBuilder(599, 1680, 19.5, 41.25),
                  ],
                  color: get("palette.text.brown"),
                  // marginBottom: [
                  //   clampBuilder(320, 599, 3.375, 6.6875),
                  //   clampBuilder(320, 599, 3.375, 6.6875),
                  //   clampBuilder(599, 1680, 1.75, 4.875),
                  //   clampBuilder(599, 1680, 1.75, 4.875),
                  // ],
                }}
              >
                {
                  data.translationsJson.pages.collection.ringsCollection
                    .linkDescription
                }
              </Paragraph>
              <LinkArrowBtn
                to={Routes.CONFIGURATOR}
                title={
                  data.translationsJson.pages.collection.ringsCollection.link
                }
                sx={{
                  marginTop: clampBuilder(599, 1680, 0.8125, 3.0625),
                  marginBottom: ["80px", "80px", "40px", "40px"],
                }}
                as={Link}
              />
              <Wrapper>
                <GridSection
                  firstChildrenType="textBox"
                  secondChildrenType="imageBox"
                >

                  <>
                    <Title
                      sx={{
                        alignSelf: "center",
                        width: "100%",
                      }}
                      variant="paragraph"
                    >
                      {data.translationsJson.pages.collection.details.title}
                    </Title>
                    <Paragraph
                      sx={{

                        alignSelf: "center",
                        width: "100%",
                      }}
                    >
                      {
                        data.translationsJson.pages.collection.details
                          .description
                      }
                    </Paragraph>
                  </>
                  <>
                    <Box
                      as={GatsbyImage}
                      sx={{}}
                      alt={
                        data.translationsJson.pages.collection.details.imageAlt
                      }
                      image={
                        data.translationsJson.pages.collection.details.image
                          .childImageSharp.gatsbyImageData
                      }
                    />
                  </>
                </GridSection>
              </Wrapper>
            </SectionWrapper2>




            <Wrapper>
              <GridSection
                firstChildrenType="imageBox"
                secondChildrenType="textBox"
              >
                <>
                  <ImageSwitch
                    btnAlign={ImageSwitchButtonAlign.LEFT}
                    images={
                      data.translationsJson.pages.collection.items[2].variants
                    }
                  />
                </>
                <>
                  <Title
                    sx={{
                      alignSelf: "center",
                      width: "100%",
                    }}
                    variant="paragraph"
                  >
                    {data.translationsJson.pages.collection.items[2].title}
                  </Title>
                  <Paragraph
                    sx={{
                      alignSelf: "center",
                      width: "100%",
                    }}
                  >
                    {
                      data.translationsJson.pages.collection.items[2]
                        .description
                    }
                  </Paragraph>
                </>
              </GridSection>
              <GridSection
                firstChildrenType="topImageBox"
                secondChildrenType="bottomTextBox"
              >
                <>
                  <ImageSwitch
                    sx={{
                      marginBottom: ["40px", "40px", "20px", "20px"],
                    }}
                    btnAlign={ImageSwitchButtonAlign.RIGHT}
                    images={
                      data.translationsJson.pages.collection.items[3].variants
                    }
                  />
                </>
                <>
                  <Title
                    sx={{
                      width: "100%",
                    }}
                    variant="paragraph"
                  >
                    {data.translationsJson.pages.collection.items[3].title}
                  </Title>
                  <Paragraph
                    sx={{
                      width: "100%",
                    }}
                  >
                    {
                      data.translationsJson.pages.collection.items[3]
                        .description
                    }
                  </Paragraph>
                </>
              </GridSection>
            </Wrapper>

          </Box>

          <Wrapper>
            <GridSection
              firstChildrenType="imageBox"
              secondChildrenType="textBox"
              mainContainerSx={{ marginBottom: ["80px", 0, 0, 0] }}
            >
              <>
                <ImageSwitch
                  btnAlign={ImageSwitchButtonAlign.LEFT}
                  images={
                    data.translationsJson.pages.collection.items[9].variants
                  }
                />
              </>
              <>
                <Title
                  sx={{
                    alignSelf: "left",
                    width: "100%",
                  }}
                  variant="paragraph"
                >
                  {data.translationsJson.pages.collection.items[9].title}
                </Title>
                <Paragraph
                  sx={{
                    alignSelf: "left",
                    width: "100%",
                  }}
                >
                  {data.translationsJson.pages.collection.items[9].description}
                </Paragraph>
              </>
            </GridSection>
          </Wrapper>
        </Box>
      </Layout>
    </>
  );
};

export default React.memo(Collection);

export const collectionQuery = graphql`
  query CollectionQuery {
    headerDesktop: file(
      relativeDirectory: { eq: "pages/collection/header" }
      name: { eq: "desktop" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    headerMobile: file(
      relativeDirectory: { eq: "pages/collection/header" }
      name: { eq: "mobile" }
    ) {
      id
      relativeDirectory
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    translationsJson {
      pages {
        collection {
          woman {
            items {
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              imagemobile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              imageAlt
              text
              bg {
                childImageSharp {
                  gatsbyImageData
                }
              }
              bgmobile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          movement {
            list {
              field
              value
            }
            title
          }
          case {
            list {
              field
              value
            }
            title
            footer
          }
          stone {
            list {
              field
              value
            }
            title
          }
          strap {
            list {
              field
              value
            }
            title
          }
          link
          seo {
            title
            description
            keywords
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          header {
            subtitle
            title
            description
          }
          details {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            imageAlt
          }
          items {
            title
            description
            variants {
              id
              title
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              imageAlt
            }
          }
          ringsCollection {
            title
            description
            linkDescription
            link
          }
        }
      }
    }
  }
`;
